import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useDialog from 'shared/hooks/dialog.hook';
import styles from './Dialog.module.scss';

function Dialog() {
  const { t } = useTranslation();
  const { isOpened, notification, confirmDialog, declineDialog } = useDialog();

  return (
    <Modal
      className="core-dialog"
      show={isOpened}
      onHide={declineDialog}
      centered
    >
      <Modal.Body className="p-5">
        <h3>{t('Confirm')}</h3>
        <div className="my-4 py-1">{t(notification)}</div>
        <button
          type="button"
          className={`btn btn-outline me-3 ${styles.action}`}
          onClick={declineDialog}
        >
          {t('No')}
        </button>
        <button
          type="button"
          className={`btn btn-primary ${styles.action}`}
          onClick={confirmDialog}
        >
          {t('Yes')}
        </button>
      </Modal.Body>
    </Modal>
  );
}

export default Dialog;
