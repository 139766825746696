import { store } from '../store';
import type { AppDispatch } from '../store.typings';
import { open } from './dialog.slice';

export const awaitDialog = (msg: string) => async (dispatch: AppDispatch) => {
  dispatch(open(msg));

  return new Promise<boolean>((resolve) => {
    const unsubscribe = store.subscribe(() => {
      const state = store.getState();

      if (state.dialog.isConfirmed) {
        unsubscribe();

        resolve(true);
      }
      if (state.dialog.isDeclined) {
        unsubscribe();

        resolve(false);
      }
    });
  });
};
