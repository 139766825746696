import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import usePermission from 'shared/hooks/permission.hook';
import useQuery from 'shared/hooks/query.hook';
import { UserRole } from 'shared/models/user.models';
import { authSelector } from 'shared/store/auth';

function AuthGuard({ children }: { children: JSX.Element }) {
  const { query } = useQuery();
  const { isLoggedIn } = useSelector(authSelector);
  const { hasPermission } = usePermission();

  if (isLoggedIn && !query.get('backup')) {
    return hasPermission(UserRole.Client) ? (
      <Navigate to="/client" />
    ) : (
      <Navigate to="/vendor" />
    );
  }

  return children;
}

export default AuthGuard;
