import { NiceMerge } from 'shared/shared.typings';
import { Schema } from './object-mapper.typings';

const Builder = {
  object: <T extends Record<string, Schema>>(properties: T) => ({
    type: 'object' as const,
    properties,
    required: true as const,
  }),
  array: <T extends Schema>(items: T) => ({
    type: 'array' as const,
    items,
    required: true as const,
  }),
  enum: <T>(val: T) => Object.values(val) as T[keyof T][],
  string: () => ({
    type: 'string' as const,
    required: true as const,
  }),
  number: () => ({
    type: 'number' as const,
    required: true as const,
  }),
  boolean: () => ({
    type: 'boolean' as const,
    required: true as const,
  }),
  date: () => ({
    type: 'date' as const,
    required: true as const,
  }),
  oneoOf: <T extends ReadonlyArray<unknown>>(properties: T) => ({
    type: 'enum' as const,
    required: true as const,
    properties,
  }),
  optional: <T extends Schema>(
    a: T
  ): NiceMerge<Omit<T, 'required'>, { required: false }> => ({
    ...a,
    required: false as const,
  }),
};

export default Builder;
