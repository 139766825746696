import axios from 'axios';
import {
  IChangePasswordModel,
  ILegalUserModel,
  IPhysicalUserModel,
  IResetPasswordModel,
  ISigninModel,
  ISigninResponse,
} from './auth.typings';

const apiURL = process.env.REACT_APP_API_URL;
const accountURL = `${apiURL}/Account`;
const signinURL = `${accountURL}/Login`;

export const signin = async (model: ISigninModel) => {
  const { data } = await axios.post<ISigninResponse>(signinURL, model);
  return data;
};

export const registerPhysicalUser = (model: IPhysicalUserModel) =>
  axios.post(`${accountURL}/RegisterPhisicalUser`, model);

export const registerLegalUser = (model: ILegalUserModel) =>
  axios.post(`${accountURL}/RegisterLegalUser`, model);

export const requirResetPassword = (email: string) =>
  axios.post(
    `${accountURL}/RequirResetPassword`,
    {},
    {
      params: { email },
    }
  );

export const resetPassword = (model: IResetPasswordModel) =>
  axios.post(`${accountURL}/ResetPassword`, model);

export const changePassword = (model: IChangePasswordModel) =>
  axios.put(`${accountURL}/ChangePassword`, model);

export const confirmAccount = (uniCode: string) =>
  axios.get(`${accountURL}/ConfirmedAccount`, {
    params: { uniCode },
  });

export const resendConfirmationLink = (mail: string) =>
  axios.post(
    `${accountURL}/ResendEmail`,
    {},
    {
      params: { mail },
    }
  );
