import { useSelector } from 'react-redux';
import { useAppDispatch } from 'shared/store';
import {
  awaitDialog,
  confirm,
  decline,
  dialogSelector,
} from 'shared/store/dialog';

function useDialog() {
  const dispatch = useAppDispatch();
  const { isOpened, notification } = useSelector(dialogSelector);

  const openDialog = async (msg: string) => {
    const payload = await dispatch(awaitDialog(msg));

    return payload;
  };

  const confirmDialog = () => dispatch(confirm());

  const declineDialog = () => dispatch(decline());

  return {
    isOpened,
    notification,
    openDialog,
    confirmDialog,
    declineDialog,
  };
}

export default useDialog;
