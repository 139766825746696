import config from 'config';
import Cookies from 'js-cookie';

export const getCookie = (name: string) =>
  Cookies.get(`${config.cookiesPrefix}_${name}`);

export const setCookie = (
  name: string,
  value: string,
  options?: Cookies.CookieAttributes
) => Cookies.set(`${config.cookiesPrefix}_${name}`, value, options);

export const removeCookie = (name: string) =>
  Cookies.remove(`${config.cookiesPrefix}_${name}`);
