import ka from 'assets/images/icons/ka.svg';
import uk from 'assets/images/icons/uk.svg';
import { getCookie } from './cookie.utils';

export const langKAPrefix = 'ka';
export const langENPrefix = 'en';

const languages = [
  {
    prefix: langKAPrefix,
    label: 'ქართული',
    img: ka,
  },
  {
    prefix: langENPrefix,
    label: 'English',
    img: uk,
  },
];

export const getLanguage = () => {
  const languageCookie = getCookie('language');

  if (languageCookie) {
    const language = languages.find((item) => item.prefix === languageCookie);
    return language ? language.prefix : languages[0].prefix;
  }

  return languages[0].prefix;
};

export default languages;
