import { GenericRecord } from 'shared/shared.typings';
import { isObject } from '../object.utils';
import {
  InferSchemaType,
  Schema,
  SchemaArray,
  SchemaObject,
} from './object-mapper.typings';

function mapArr<
  TSource extends GenericRecord,
  TDestination extends SchemaObject
>(src: unknown, dest: Schema) {
  if (dest.type === 'object' && isObject(src))
    return mapObj(src as TSource, dest as TDestination);

  if (dest.type === 'array' && Array.isArray(src)) {
    const res: unknown[] = [];

    src.forEach((item: unknown) => {
      const each = mapArr(item, dest.items);
      if (each === null) return;
      (res as unknown[]).push(each);
    });

    return res;
  }

  if (typeof src === dest.type) return src;

  return null;
}

function mapObj<
  TSource extends GenericRecord,
  TDestination extends SchemaObject
>(src: TSource, dest: TDestination) {
  const srcKeys = Object.keys(src);
  const destKeys = Object.keys(dest.properties);
  const res: Record<string, unknown> = {};

  for (let i = 0; i < destKeys.length; i++) {
    const destKey = destKeys[i];
    const castedResKey = destKey as keyof typeof res;
    const srcKey = srcKeys.find((key) => key === destKey) as keyof TSource;
    const destProp = dest.properties[destKey];
    const srcProp = src[srcKey];

    if (!srcKeys.includes(destKey)) {
      res[castedResKey] = null;
      continue;
    }

    if (destProp.type === 'object' && isObject(srcProp)) {
      res[castedResKey] = mapObj(srcProp, destProp as TDestination);
      continue;
    }

    if (destProp.type === 'array' && Array.isArray(srcProp)) {
      res[castedResKey] = [];

      src[srcKey].forEach((item: unknown) => {
        const each = mapArr(item, (destProp as SchemaArray).items);
        if (each === null) return;
        (res[castedResKey] as unknown[]).push(each);
      });
      continue;
    }

    if (
      destProp.type === 'enum' &&
      (typeof srcProp === 'string' || typeof srcProp === 'number') &&
      destProp.properties.includes(srcProp)
    ) {
      res[castedResKey] = destProp.properties.find((item) => item === srcProp);
      continue;
    }

    if (
      destProp.type === 'date' &&
      Object.prototype.toString.call(srcProp) === '[object Date]'
    ) {
      res[castedResKey] = srcProp;
      continue;
    }

    if (typeof srcProp !== destProp.type) {
      res[castedResKey] = null;
      continue;
    }

    res[castedResKey] = srcProp;
  }

  return res as Partial<InferSchemaType<typeof dest>>;
}

function Map<TSource extends GenericRecord, TDestination extends SchemaObject>(
  source: TSource,
  destination: TDestination,
  callback?: (
    model?: Partial<InferSchemaType<typeof destination>>
  ) => Partial<InferSchemaType<typeof destination>>
): InferSchemaType<typeof destination> {
  let model: Partial<InferSchemaType<typeof destination>> = mapObj(
    source,
    destination
  );

  if (callback) {
    model = mapObj({ ...model, ...callback(model) }, destination);
  }

  return model as InferSchemaType<typeof destination>;
}

export default Map;
