import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getLanguage } from 'shared/utils/language.utils';
import type { RootState } from '../store.typings';

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    pageTitle: { title: 'Title', options: {} },
    pageLoading: 0,
    isConnectedToHub: false,
    language: getLanguage(),
    isLanguageChangerOpen: false,
  },
  reducers: {
    setPageTitle: (
      state,
      {
        payload,
      }: PayloadAction<{ title: string; options?: Record<string, string> }>
    ) => {
      state.pageTitle.title = payload.title;

      if (payload.options) state.pageTitle.options = payload.options;
    },
    increasePageLoading: (state, { payload = 1 }: PayloadAction<number>) => {
      state.pageLoading += payload;
    },
    decreasePageLoading: (state, { payload = 1 }: PayloadAction<number>) => {
      const calculated = state.pageLoading - payload;
      state.pageLoading = calculated <= 0 ? 0 : calculated;
    },
    setIsConnectedToHub: (state, { payload }: PayloadAction<boolean>) => {
      state.isConnectedToHub = payload;
    },
    changeLanguage: (state, { payload }: PayloadAction<string>) => {
      state.language = payload;
    },
    toggleLanguageChanger: (state, { payload }: PayloadAction<boolean>) => {
      state.isLanguageChangerOpen = payload;
    },
  },
});

export const {
  setPageTitle,
  increasePageLoading,
  decreasePageLoading,
  setIsConnectedToHub,
  changeLanguage,
  toggleLanguageChanger,
} = appSlice.actions;

export const appSelector = (state: RootState) => state.app;
