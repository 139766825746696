import NotFound from 'features/NotFound';
import { lazy } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import AuthGuard from 'shared/guards/auth.guard';
import OfficeGuard from 'shared/guards/office.guard';

const HomeLayout = lazy(() => import('core/layouts/HomeLayout'));
const InfoLayout = lazy(() => import('core/layouts/InfoLayout'));
const AuthLayout = lazy(() => import('core/layouts/AuthLayout'));
const ClientOfficeLayout = lazy(
  () => import('core/layouts/OfficeLayout/ClientOfficeLayout')
);
const VendorOfficeLayout = lazy(
  () => import('core/layouts/OfficeLayout/VendorOfficeLayout')
);

function AppRouting() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={<HomeLayout />} />
        <Route
          path="/auth/*"
          element={
            <AuthGuard>
              <AuthLayout />
            </AuthGuard>
          }
        />
        <Route path="/info/*" element={<InfoLayout />} />
        <Route
          path="/client/*"
          element={
            <OfficeGuard isForClient>
              <ClientOfficeLayout />
            </OfficeGuard>
          }
        />
        <Route
          path="/vendor/*"
          element={
            <OfficeGuard>
              <VendorOfficeLayout />
            </OfficeGuard>
          }
        />
        <Route path="/404" element={<NotFound />} />
        <Route path="*" element={<Navigate replace to="/404" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default AppRouting;
