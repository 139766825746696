function gtm(id) {
  const script = document.createElement('script');
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtag/js?id=${id}`;
  document.head.prepend(script);

  window.dataLayer = window.dataLayer || [];

  function gtag() {
    // eslint-disable-next-line prefer-rest-params, no-undef
    dataLayer.push(arguments);
  }

  gtag('js', new Date());
  gtag('config', id);
}

export default gtm;
