import { useSelector } from 'react-redux';
import { UserRole, userRoleRelations } from 'shared/models/user.models';
import { authSelector } from 'shared/store/auth';

function usePermission() {
  const { user } = useSelector(authSelector);

  const hasPermission = (role: UserRole, exact?: boolean) => {
    if (!user) return false;

    if (user.role === role) return true;

    const childRoles = userRoleRelations[role] || [];

    if (!exact && childRoles.length && childRoles.includes(user.role)) {
      return true;
    }

    return false;
  };

  return { hasPermission };
}

export default usePermission;
