import config from 'config';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  appSelector,
  changeLanguage,
  toggleLanguageChanger,
} from 'shared/store/app';
import { setCookie } from 'shared/utils/cookie.utils';

function useLanguageChanger() {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const { language, isLanguageChangerOpen } = useSelector(appSelector);

  const open = () => dispatch(toggleLanguageChanger(true));

  const close = () => dispatch(toggleLanguageChanger(false));

  const change = async (prefix: string) => {
    await i18n.changeLanguage(prefix);

    dispatch(changeLanguage(prefix));

    setCookie('language', prefix, {
      expires: config.languageExpirationTimeInDays,
    });

    close();
  };

  return {
    language,
    isLanguageChangerOpen,
    open,
    close,
    change,
  };
}

export default useLanguageChanger;
