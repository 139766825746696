import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { alertSlice } from './alert/alert.slice';
import { appSlice } from './app/app.slice';
import { authSlice } from './auth/auth.slice';
import { dialogSlice } from './dialog/dialog.slice';
import { homeCarouselSlice } from './home-carousel/home-carouse.slice';
import type { AppDispatch } from './store.typings';

function createStore() {
  const extraArgument: Partial<{ store: unknown }> = {};

  const store = configureStore({
    reducer: {
      app: appSlice.reducer,
      auth: authSlice.reducer,
      alert: alertSlice.reducer,
      dialog: dialogSlice.reducer,
      homeCarousel: homeCarouselSlice.reducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: {
          extraArgument,
        },
      }),
  });

  extraArgument.store = store;

  return store;
}

export const store = createStore();

export const useAppDispatch: () => AppDispatch = useDispatch;
