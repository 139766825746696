import AppRouting from 'app.routing';
import {
  Alerts,
  Dialog,
  HubConnectionManager,
  LanguageChanger,
} from 'core/components';
import 'core/i18n';
import { BaseInterceptor } from 'core/interceptors';
import 'core/seo';
import { Suspense } from 'react';
import { Provider } from 'react-redux';
import { store } from 'shared/store';

const pageLoading = '';

function App() {
  return (
    <Provider store={store}>
      <Suspense fallback={pageLoading}>
        <HubConnectionManager />
        <Alerts />
        <Dialog />
        <LanguageChanger />
        <BaseInterceptor />

        <AppRouting />
      </Suspense>
    </Provider>
  );
}

export default App;
