export enum InvoiceType {
  OrderDocument = 1,
  Invoice,
  VendorDocument,
  FinalInvoice,
  Contract,
  ForwarderInvoice,
}

export enum DocumentType {
  Requested = 1,
  Uploaded,
}

export interface IDocument {
  id: number;
  name: string;
}

export enum DriverState {
  OnTheWay = 1,
  Arrived,
  Unloaded,
}

// IBidAddResponse
export interface IBidAddResponse {
  isVendorAction: boolean;
  vendorId: number;
  amount: number;
  vendorRating: number;
}

// IBidRejectionResponse
export interface IBidRejectedResponse {
  isVendorAction: boolean;
  vendorUserId: number;
}

// IBidAcceptResponse
export interface IBidAcceptedResponse {
  auctionId: number;
  isVendorAction: boolean;
  vendorUserId: number;
  orderId: number;
}

// IBidSeenResponse
export interface IBidHasBeenSeenResponse {
  auctionId: number;
  isVendorAction: boolean;
  vendorUserId: number;
  orderId: number;
}

// IUploadedDocumentResponse
export interface IUploadedDocumentResponse {
  id: number;
  fileKey: string;
  name: string;
  isUploadedByVendor: boolean;
  requestedDocumentIds: number[];
}

// IDocumentDeleteResponse
export interface IDocumentDeleteResponse {
  documentState: DocumentType;
  documentId: number;
  requestedDocuments: IDocument[];
}

// IDocumentNameChangeResponse
export interface IDocumentNameChangeResponse {
  documentId: number;
  documentName: string;
}

// IInvoiceGenerationResponse
export interface IInvoiceGenerationResponse {
  fileKey: string;
  documentType: InvoiceType;
}

// IDocumentModel
export interface IUploadDocumentPayload {
  inlandOrderId: number;
  documentData: string;
  documentName: string;
  inlandOrderRequestedDocumentIds: number[];
}

// IInvoiceGenerationModel
export interface IGenerateInvoicePayload {
  orderId: number;
  documentType: InvoiceType;
  isMultiOrder: boolean;
  additionalInvoiceServices?: {
    id: number;
    serviceName: string;
    price: number;
  }[];
}

// IOrderMessage
export interface IReceiveOrderMessageResponse {
  id: number;
  senderId: number;
  senderName: string;
  orderId: number;
  orderNumber: string;
  message: string;
  createDate: string;
  reciveUserId: number;
  reviceUserMail: string;
}

// IPriceChangeResponse
export interface IPriceChangeResponse {
  orderId: number;
  orderPrice: number;
}

// IMaritimeLineChangeResponse
export interface IMaritimeLineChangeResponse {
  orderId: number;
  name: string;
}

// IContainerNumberResponse
export interface IContainerNumberResponse {
  id: number;
  containerNumber: string;
}

// IFeedbackAddResponse
export interface IFeedbackAddResponse {
  stars: number;
  comment: string;
}

// IPriceStatusChangeResponse
export interface IPriceStatusChangeResponse {
  orderId: number;
  isAccepted: boolean;
}

// IContractStatusChangeResponse
export interface IContractStatusChangeResponse {
  isAccepted: boolean;
}

// IPriceConfirmationAddResponse
export interface IPriceConfirmationAddResponse {
  orderId: number;
  orderPrice: number;
  orderNumber: string;
}

// IPriceConfirmationModel
export interface IPriceConfirmationModel {
  orderId: number;
  isAccepted: boolean;
  childOrderId?: number;
}

// IDriverCoordinatesResponse
export interface IDriverCoordinatesResponse {
  orderId: number;
  latitude: number;
  longitude: number;
}

// IDriverIsActiveChangeResponse
export interface IDriverIsActiveChangeResponse {
  orderId: number;
  isActive: boolean;
}

// IDriverStateChangeResponse
export interface IDriverStateChangeResponse {
  orderId: number;
  driverStateId: DriverState;
}

// ISubStatusChangeResponse
export interface ISubStatusChangeResponse {
  orderId: number;
  subStatusTypeId: number;
}
