import ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';
import { alertSelector } from 'shared/store/alert';
import styles from './Alerts.module.scss';
import Each from './Each';

const alertRoot = document.getElementById('alert-root');

function Alerts() {
  const alerts = useSelector(alertSelector);

  if (!alerts.length) return null;

  return ReactDOM.createPortal(
    <div className={styles.wrapper}>
      {alerts.map((item, i) => (
        <Each key={+i} item={item} />
      ))}
    </div>,
    alertRoot
  );
}

export default Alerts;
