import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from 'shared/models/user.models';
import type { RootState } from '../store.typings';
import authInitialState from './auth.initial-state';

export const authSlice = createSlice({
  name: 'auth',
  initialState: authInitialState(),
  reducers: {
    signin: (
      state,
      { payload }: PayloadAction<{ token: string; user: User }>
    ) => {
      state.isLoggedIn = true;
      state.token = payload.token;
      state.user = payload.user;
      state.isFetching = false;
    },
    signinPending: (state) => {
      state.isFetching = true;
    },
    signinRejected: (state) => {
      state.isFetching = false;
      state.isError = true;
    },
    setEmailConfirmed: (state) => {
      state.isEmailConfirmed = true;
      state.isFetching = false;
      state.isError = false;
    },
    resetEmailConfirmed: (state) => {
      state.isEmailConfirmed = false;
    },
    setShouldChangePassword: (state, { payload }) => {
      state.token = payload;
      state.shouldChangePassword = true;
      state.isFetching = false;
      state.isError = false;
    },
    resetShouldChangePassword: (state) => {
      state.shouldChangePassword = false;
      state.token = null;
    },
    setIsInactive: (state) => {
      state.isInactive = true;
      state.isFetching = false;
      state.isError = false;
    },
    resetIsInactive: (state) => {
      state.isInactive = false;
    },
    clearState: (state) => {
      state.isError = false;
      state.isFetching = false;
      state.isEmailConfirmed = false;
      state.shouldChangePassword = false;
      state.isInactive = false;
    },
    logout: (state) => {
      state.isLoggedIn = false;
      state.token = null;
      state.user = null;
    },
    updateUser: (state, { payload }: PayloadAction<User>) => {
      state.user = payload;
    },
  },
});

export const authSelector = (state: RootState) => state.auth;

export const {
  signin,
  signinPending,
  signinRejected,
  setEmailConfirmed,
  resetEmailConfirmed,
  setShouldChangePassword,
  resetShouldChangePassword,
  setIsInactive,
  resetIsInactive,
  clearState,
  logout,
  updateUser,
} = authSlice.actions;
