import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styles from './NotFound.module.scss';

function NotFound() {
  const { t } = useTranslation();

  return (
    <div className="flex-center w-100 vh-100 text-center">
      <div>
        <h1 className={`mb-0 ${styles.code}`}>404</h1>
        <p className={`${styles.message} mt-4 mb-5 `}>{t('NotFound')}</p>
        <Link to="/" className="btn btn-primary">
          {t('GoBack')}
        </Link>
      </div>
    </div>
  );
}

export default NotFound;
