import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import usePermission from 'shared/hooks/permission.hook';
import { UserRole } from 'shared/models/user.models';
import { authSelector } from 'shared/store/auth';

interface IProps {
  isForClient?: boolean;
  children: JSX.Element;
}

function OfficeGuard({ isForClient = false, children }: IProps) {
  const { isLoggedIn } = useSelector(authSelector);
  const { hasPermission } = usePermission();

  if (!isLoggedIn) {
    return <Navigate replace to="/auth/signin" />;
  }

  if (isForClient && hasPermission(UserRole.Vendor)) {
    return <Navigate replace to="/vendor/orders" />;
  }

  if (!isForClient && hasPermission(UserRole.Client)) {
    return <Navigate replace to="/client/orders" />;
  }

  return children;
}

export default OfficeGuard;
