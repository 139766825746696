import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store.typings';

export const dialogSlice = createSlice({
  name: 'dialog',
  initialState: {
    notification: '',
    isOpened: false,
    isConfirmed: false,
    isDeclined: false,
  },
  reducers: {
    open: (state, { payload }: PayloadAction<string>) => {
      state.notification = payload;
      state.isOpened = true;
      state.isConfirmed = false;
      state.isDeclined = false;
    },
    confirm: (state) => {
      state.isConfirmed = true;
      state.isOpened = false;
    },
    decline: (state) => {
      state.isDeclined = true;
      state.isOpened = false;
    },
  },
});

export const { open, confirm, decline } = dialogSlice.actions;

export const dialogSelector = (state: RootState) => state.dialog;
