import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function useQuery() {
  const { search } = useLocation();
  const navigate = useNavigate();
  const query = useMemo(() => new URLSearchParams(search), [search]);

  const removeFromQuery = useCallback(
    (str: string) => {
      if (!query.has(str)) return;

      query.delete(str);
      navigate({
        search: query.toString(),
      });
    },
    [query, navigate]
  );

  return useMemo(
    () => ({
      query,
      removeFromQuery,
    }),
    [query, removeFromQuery]
  );
}

export default useQuery;
