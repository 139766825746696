import {
  HubConnection,
  HubConnectionBuilder,
  LogLevel,
} from '@microsoft/signalr';

export class HubConnectionAdapter {
  connection: HubConnection;

  private startConnection() {
    return this.connection?.start();
  }

  private buildConnection(url: string, token: string) {
    if (this.connection) return;

    this.connection = new HubConnectionBuilder()
      .withUrl(url, {
        accessTokenFactory: () => token,
      })
      .configureLogging(LogLevel.None)
      .build();
  }

  connect(url: string, token: string) {
    this.buildConnection(url, token);
    return this.startConnection();
  }

  async disconnect() {
    if (!this.connection) return;

    await this.connection.stop();
    this.connection = null;
  }
}

export const hubConnectionAdapter = new HubConnectionAdapter();
