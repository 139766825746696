import config from 'config';
import gtm from './utils/gtm';
import linkedinInsight from './utils/linkedin';
import metaPixel from './utils/meta-pixel';

if (config.seoToolsEnvironments.some((evn) => evn === process.env.NODE_ENV)) {
  metaPixel(process.env.REACT_APP_FB_PIXELID);
  gtm(process.env.REACT_APP_GTMID);
  linkedinInsight(process.env.REACT_APP_LINKEDIN_PARTNERID);
}
