import config from 'config';
import { hubConnectionAdapter } from 'shared/hub';
import { UserRole, userSchema } from 'shared/models/user.models';
import { signin as signinMethod } from 'shared/services/auth/auth.service';
import { ISigninModel } from 'shared/services/auth/auth.typings';
import {
  backupAccount,
  removeAuthCookies,
  saveAuthTokens,
} from 'shared/utils/auth.utils';
import { Map } from 'shared/utils/object-mapper';
import { setIsConnectedToHub } from '../app';
import type { AppDispatch } from '../store.typings';
import {
  logout,
  setEmailConfirmed,
  setIsInactive,
  setShouldChangePassword,
  signin,
  signinPending,
  signinRejected,
} from './auth.slice';

const { tokenExpirationTimeInDays } = config;

export const signinAction =
  (model: ISigninModel, backup: boolean, onSuccess: () => void) =>
  async (dispatch: AppDispatch) => {
    dispatch(signinPending());

    if (backup) {
      await backupAccount();
    }

    try {
      const data = await signinMethod(model);

      if (!data.emailConfirmed) {
        dispatch(setEmailConfirmed());
      } else if (data.shouldChangePassword) {
        dispatch(setShouldChangePassword(data.jwtToken));
      } else if (!data.accountConfirmed) {
        dispatch(setIsInactive());
      } else {
        const user = Map(data, userSchema, (c) => ({
          ...c,
          role: UserRole[data?.userRoleName]
            ? UserRole[data.userRoleName]
            : UserRole.Client,
        }));
        const today = new Date();
        const expTime = today.setDate(
          today.getDate() + tokenExpirationTimeInDays
        );

        await saveAuthTokens({
          token: data.jwtToken,
          user,
          expTime,
        });

        dispatch(signin({ token: data.jwtToken, user }));

        if (onSuccess) onSuccess();
      }
    } catch (error) {
      dispatch(signinRejected());
    }
  };

export const logoutAction = () => async (dispatch: AppDispatch) => {
  await removeAuthCookies();
  dispatch(setIsConnectedToHub(false));
  dispatch(logout());
  await hubConnectionAdapter.disconnect();
};
