import { useEffect, useRef } from 'react';
import { Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Alert as AlertType, removeAlert } from 'shared/store/alert';

function UIAlert({ item }: { item: AlertType }) {
  const { t } = useTranslation();
  const initial = useRef(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (initial.current) {
      setTimeout(() => {
        dispatch(removeAlert(item.id));
      }, item.time);

      initial.current = false;
    }
  }, [item, dispatch]);

  return (
    <Alert className="mb-0 mb-3 mx-3" variant={item.variant}>
      {t(item.message)}
    </Alert>
  );
}

export default UIAlert;
