import Axios from 'axios';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createAlert } from 'shared/store/alert';
import { appSelector } from 'shared/store/app';
import { authSelector } from 'shared/store/auth';
import errorCodes from 'shared/utils/error-codes.utils';

function BaseInterceptor(): JSX.Element {
  const dispatch = useDispatch();
  const { token } = useSelector(authSelector);
  const { language } = useSelector(appSelector);

  useEffect(() => {
    const requestInterceptor = Axios.interceptors.request.use(
      async (conf) => {
        const config = conf;

        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }

        if (language) {
          config.headers['Accept-language'] = language;
        }

        return config;
      },
      (error) => Promise.reject(error)
    );

    const responseInterceptor = Axios.interceptors.response.use(
      (next) => Promise.resolve(next),
      (err) => {
        let message = 'Alerts.Error';

        if (
          err.response &&
          typeof err.response.data === 'string' &&
          errorCodes[err.response.data]
        ) {
          message = errorCodes[err.response.data];
        }

        dispatch(
          createAlert({
            message,
            variant: 'danger',
          })
        );

        return Promise.reject(err);
      }
    );

    return () => {
      Axios.interceptors.request.eject(requestInterceptor);
      Axios.interceptors.response.eject(responseInterceptor);
    };
  }, [token, language, dispatch]);

  return null;
}

export default BaseInterceptor;
