export const isObject = (src: unknown) =>
  typeof src === 'object' && !Array.isArray(src) && src !== null;

export const objectMap = <
  T extends string | number | symbol,
  U extends Record<T, unknown>
>(
  obj: U,
  mapFn: (prop: U[keyof U]) => U[keyof U]
) => {
  const mapped: Partial<U> = {};

  (Object.keys(obj) as (keyof typeof obj)[]).forEach((key) => {
    mapped[key] = mapFn(obj[key]);
  }, {});

  return mapped;
};
