import { User } from 'shared/models/user.models';
import { getCookie } from 'shared/utils/cookie.utils';

const authInitialState = () => {
  const storedToken = getCookie('token');
  const storedUser = getCookie('user');

  return {
    isLoggedIn: !!storedToken,
    token: storedToken || null,
    user: storedUser ? (JSON.parse(storedUser) as User) : null,
    isFetching: false,
    isError: false,
    isEmailConfirmed: false,
    shouldChangePassword: false,
    isInactive: false,
  };
};

export default authInitialState;
