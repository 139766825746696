import { Builder, InferSchemaType } from 'shared/utils/object-mapper';

export enum UserType {
  Vendor = 'Vendor',
  Client = 'Client',
  SubVendor = 'SubVendor',
}

export enum UserRole {
  Vendor = 'Vendor',
  Client = 'Client',
  SubVendor = 'SubVendor',
}

export const userSchema = Builder.object({
  id: Builder.number(),
  thumbnail: Builder.string(),
  firstName: Builder.string(),
  lastName: Builder.string(),
  email: Builder.string(),
  role: Builder.oneoOf(Builder.enum(UserRole)),
  isLegal: Builder.boolean(),
});

export type User = InferSchemaType<typeof userSchema>;

// user relation parent - child
export const userRoleRelations: Partial<Record<UserRole, UserRole[]>> = {
  [UserRole.Vendor]: [UserRole.SubVendor],
  [UserRole.Client]: [],
};

export type BackupUser = {
  token: string;
  user: User;
  expires: number;
};
