import config from 'config';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hubConnectionAdapter } from 'shared/hub';
import { setIsConnectedToHub } from 'shared/store/app';
import { authSelector } from 'shared/store/auth';
import { getCookie } from 'shared/utils/cookie.utils';

const hubURL = process.env.REACT_APP_SIGNALR_URL;

function HubConnectionManager(): JSX.Element {
  const dispatch = useDispatch();
  const { token } = useSelector(authSelector);
  const [reconnect, setReconnect] = useState(false);
  const reconnectionAttemptCount = useRef(0);

  // build signalr connection and handle reconnection
  useEffect(() => {
    if (!token) return;

    hubConnectionAdapter
      .connect(hubURL, token)
      .then(() => {
        reconnectionAttemptCount.current = 0;
        dispatch(setIsConnectedToHub(true));

        hubConnectionAdapter.connection.onclose(() => {
          const cookieToken = getCookie('token');

          if (cookieToken) {
            dispatch(setIsConnectedToHub(false));
          }

          setReconnect((s) => !s);
        });
      })
      .catch(() => {
        setTimeout(() => {
          if (reconnectionAttemptCount.current < config.hubReconnectionMaxTry) {
            reconnectionAttemptCount.current++;
            setReconnect((s) => !s);
          }
        }, config.hubReconnectionDelay);
      });
  }, [token, reconnect, dispatch]);

  return null;
}

export default HubConnectionManager;
