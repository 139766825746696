import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useLanguageChanger from 'shared/hooks/language-changer.hook';
import languages from 'shared/utils/language.utils';
import styles from './LanguageChanger.module.scss';

function LanguageChanger() {
  const { t } = useTranslation();
  const { language, isLanguageChangerOpen, change, close } =
    useLanguageChanger();

  return (
    <Modal
      className={`core-dialog ${styles.wrapper}`}
      show={isLanguageChangerOpen}
      onHide={close}
      centered
    >
      <Modal.Body className="py-4">
        <h3>{t('SelectLanguage')}</h3>

        <div className="mt-4 py-2">
          {languages.map((item) => {
            const isActive = language === item.prefix;

            return (
              <button
                type="button"
                key={item.prefix}
                className={`d-flex align-items-center ${styles.each} ${
                  isActive ? styles.active : ''
                }`}
                onClick={() => change(item.prefix)}
              >
                <img src={item.img} alt={item.label} />
                {item.label}
              </button>
            );
          })}
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default LanguageChanger;
