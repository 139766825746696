import { BackupUser, User } from 'shared/models/user.models';
import { getCookie, removeCookie, setCookie } from './cookie.utils';

export async function saveAuthTokens({
  token,
  user,
  expTime,
}: {
  token: string;
  user: User;
  expTime: number;
}) {
  const expires = new Date(+expTime);

  setCookie('token', token, { expires });
  setCookie('user', JSON.stringify(user), { expires });
  setCookie('expire_date', expTime.toString(), { expires });
}

export async function backupAccount() {
  const storedToken = getCookie('token');
  const storedUser = getCookie('user');
  const storedExpires = Number(getCookie('expire_date'));
  const user: User = storedUser ? JSON.parse(storedUser) : null;

  if (!storedToken || !user || !storedExpires) return;

  const val: BackupUser = {
    token: storedToken,
    user,
    expires: storedExpires,
  };

  setCookie('backup_account', JSON.stringify(val), {
    expires: storedExpires,
  });
}

export async function removeAuthCookies() {
  removeCookie('token');
  removeCookie('user');
  removeCookie('expire_date');
  removeCookie('backup_account');
}
