import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store.typings';

const initialState: { active: number } = {
  active: null,
};

export const homeCarouselSlice = createSlice({
  name: 'homeCarousel',
  initialState,
  reducers: {
    setActive: (state, { payload }: PayloadAction<number>) => {
      state.active = payload;
    },
  },
});

export const { setActive } = homeCarouselSlice.actions;
export const homeCarouselSelector = (state: RootState) => state.homeCarousel;
