import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import config from 'config';
import type { RootState } from '../store.typings';
import { Alert } from './alert.typings';

const initialState: Alert[] = [];

export const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    createAlert: (state, { payload }: PayloadAction<Alert>) => {
      const { length } = state;
      const id = length ? state[length - 1].id + 1 : 0;

      state.push({
        id,
        message: payload.message,
        variant: payload.variant,
        time: payload.time || config.alertVisibilityTime,
      });
    },
    removeAlert: (state, { payload }: PayloadAction<number>) => {
      const index = state.findIndex(({ id }) => id === payload);

      state.splice(index, 1);
    },
  },
});

export const { createAlert, removeAlert } = alertSlice.actions;

export const alertSelector = (state: RootState) => state.alert;
