export const AddUserToGroup = 'AddUserToGroup';
export const RemoveUserFromGroup = 'RemoveUserFromGroup';
export const UploadDocument = 'UploadDocument';
export const RecieveRequestDocument = 'RecieveRequestDocument';
export const GetUploadDocument = 'GetUploadDocument';
export const ReceiveOrderMessage = 'ReceiveOrderMessage';
export const SendOrderMessage = 'SendOrderMessage';
export const GenerateInvoice = 'GenerateInvoice';
export const GetGenerateInvoice = 'GetGenerateInvoice';
export const ChangeOrderStatus = 'ChangeOrderStatus';
export const ReciveChangeOrderStatus = 'ReciveChangeOrderStatus';
export const ChangeOrderPrice = 'ChangeOrderPrice';
export const GetChangeOrderPrice = 'GetChangeOrderPrice';
export const RequestDocument = 'RequestDocument';
export const ReciveNotification = 'ReciveNotification';
export const DeleteDocument = 'DeleteDocument';
export const DeleteRequestedDocument = 'DeleteRequestedDocument';
export const ChangeDocumentName = 'ChangeDocumentName';
export const GetDeleteDocument = 'GetDeleteDocument';
export const GetChangeDocumentName = 'GetChangeDocumentName';
export const GetContractConfirmation = 'GetContractConfirmation';
export const ChangePriceState = 'ChangePriceState';
export const ChangeContractFlow = 'ChangeContractFlow';
export const ResendContract = 'ResendContract';
export const ChangeMaritimeLineType = 'ChangeMaritimeLineType';
export const GetChangeMaritimeLineType = 'GetChangeMaritimeLineType';
export const GetContainerNumber = 'GetContainerNumber';
export const SetContainerNumber = 'SetContainerNumber';
export const AddBid = 'AddBid';
export const AcceptBid = 'AcceptBid';
export const RejectBid = 'RejectBid';
export const BidAdded = 'BidAdded';
export const BidAccepted = 'BidAccepted';
export const BidRejected = 'BidRejected';
export const BiddingIsOver = 'BiddingIsOver';
export const AuctionCancelled = 'AuctionCancelled';
export const BidHasBeenSeen = 'BidHasBeenSeen';
export const AddInlandOrderFeedback = 'AddInlandOrderFeedback';
export const FeedbackAdded = 'FeedbackAdded';
export const GetChangeOrderPriceResponse = 'GetChangeOrderPriceResponse';
export const ChangeContractStatus = 'ChangeContractStatus';
export const MinimumBidAmountResponse = 'MinimumBidAmountResponse';
export const CoordinatsAdded = 'CoordinatsAdded';
export const ChangedDriverIsActive = 'ChangedDriverIsActive';
export const ReceiveChangeOrderSubStatusType =
  'ReceiveChangeOrderSubStatusType';
export const ChangeOrderSubStatus = 'ChangeOrderSubStatus';
export const ChangedDriverState = 'ChangedDriverState';
